import revive_payload_client_4sVQNw7RlN from "C:/Users/kamil.kruszona/Desktop/metana-website-master/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/Users/kamil.kruszona/Desktop/metana-website-master/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Users/kamil.kruszona/Desktop/metana-website-master/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/Users/kamil.kruszona/Desktop/metana-website-master/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "C:/Users/kamil.kruszona/Desktop/metana-website-master/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/Users/kamil.kruszona/Desktop/metana-website-master/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tLmAP220nC from "C:/Users/kamil.kruszona/Desktop/metana-website-master/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "C:/Users/kamil.kruszona/Desktop/metana-website-master/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "C:/Users/kamil.kruszona/Desktop/metana-website-master/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_i8AMfKeYnY from "C:/Users/kamil.kruszona/Desktop/metana-website-master/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "C:/Users/kamil.kruszona/Desktop/metana-website-master/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/Users/kamil.kruszona/Desktop/metana-website-master/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tLmAP220nC,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ
]