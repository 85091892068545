import { default as indexqbqayDEIzuMeta } from "C:/Users/kamil.kruszona/Desktop/metana-website-master/pages/index.vue?macro=true";
export default [
  {
    name: indexqbqayDEIzuMeta?.name ?? "index___pl",
    path: indexqbqayDEIzuMeta?.path ?? "/",
    meta: indexqbqayDEIzuMeta || {},
    alias: indexqbqayDEIzuMeta?.alias || [],
    redirect: indexqbqayDEIzuMeta?.redirect || undefined,
    component: () => import("C:/Users/kamil.kruszona/Desktop/metana-website-master/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexqbqayDEIzuMeta?.name ?? "index___en",
    path: indexqbqayDEIzuMeta?.path ?? "/en",
    meta: indexqbqayDEIzuMeta || {},
    alias: indexqbqayDEIzuMeta?.alias || [],
    redirect: indexqbqayDEIzuMeta?.redirect || undefined,
    component: () => import("C:/Users/kamil.kruszona/Desktop/metana-website-master/pages/index.vue").then(m => m.default || m)
  }
]